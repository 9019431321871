import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/Removal-766.png";
import navIcon1 from "../assets/img/upwork-svgrepo-com.svg";
import navIcon2 from "../assets/img/playstore-svgrepo-com.svg";
import navIcon3 from "../assets/img/itchio.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {
                /* <a href="https://www.upwork.com/freelancers/~0170f3b2098cb07fbe" target="_blank" rel="noopener noreferrer">
                  <img src={navIcon1} alt="Upwork" style={{ width: "60px", height: "60px", marginRight: "0px" }} />
                </a> */
              }
              <a href="https://play.google.com/store/apps/developer?id=RomsDream.Inc" target="_blank" rel="noopener noreferrer">
                <img src={navIcon2} alt="Playstore" style={{ width: "60px", height: "60px", marginRight: "0px" }} />
              </a>
              <a href="https://ang3ldesu.itch.io/" target="_blank" rel="noopener noreferrer">
                <img src={navIcon3} alt="Itch.io" style={{ width: "60px", height: "60px" }} />
              </a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
