import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/Astronaut.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import 'animate.css/animate.css';
export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["I am a", "Game Developer"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);
  
    setText(updatedText);
  
    if (isDeleting) {
      setDelta(50); // Adjust this value to control the deleting speed
    } else {
      setDelta(200); // Adjust this value to control the typing speed
    }
  
    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(1000); // Pause before deleting
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };
  
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline animate__animated animate__bounceIn animate__bounce">Welcome to my Portfolio</span>
                <h1>{`Hi, AngelDesu Here.`} <span className="txt-rotate" dataPeriod="1000" data-rotate='["Game Developer", "I am a"]'><span className="wrap">{text}</span></span></h1>
                <p className="text-white">Hello, I'm a seasoned game developer with three years of hands-on experience in the dynamic world of game development. My expertise spans across various game engines, including Cocos, Unity, and Egret. Throughout my career, I've had the privilege of working on both collaborative team projects and exciting personal ventures. My passion for creating immersive and engaging gaming experiences has driven me to constantly refine my skills and stay at the forefront of the ever-evolving game development landscape.</p>
                  {
                  /*<button onClick={() => { console.log('connect'); window.location.href = '#skills'; }}>
                    Let’s Connect
                    <ArrowRightCircle size={25} />
                  </button>*/
                  }
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
