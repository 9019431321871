import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const skillsData = [
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Unity</span>, percentage: 80 },
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Cocos</span>, percentage: 70 },
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Egret</span>, percentage: 45 },
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Photon</span>, percentage: 40 },
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Ts</span>, percentage: 50 },
    { skill: <span style={{ color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Js</span>, percentage: 50 },
  ];

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
              <span style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Game Engines:</span><br></br>
              <span style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>Cocos:</span> Extensive experience in Cocos game engine, specializing in the development of various mini-games that showcase creativity and versatility.<br></br>
              <span style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>Unity:</span> Proficient in Unity game engine with a notable contribution to a professional game project, demonstrating expertise in creating polished and market-ready gaming experiences. <br></br>
              <span style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>Egret:</span> Collaborated as a developer in a large team for the development of 2 professional simulation projects. Contributed to the success of these projects.
            </p>
              <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000} className="owl-carousel owl-theme skill-slider">
                {skillsData.map(({ skill, percentage }, index) => (
                  <div className="item" key={index}>
                    <div className="circular-meter-container">
                      <div className="circular-meter" style={{ transform: `rotate(${(percentage / 100) * 360}deg)` }}></div>
                    </div>
                    <span>{percentage}%</span>
                    <h5>{skill}</h5>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
}
