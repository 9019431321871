import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projUnityImg1 from "../assets/img/Little_Ghosti.png";
import projConsoleImg1 from "../assets/img/RomsLogo.png";
import projCocosImg1 from "../assets/img/Duck_Hunt.png";
import projCocosImg2 from "../assets/img/Sheep_Jump.png";
import projCocosImg3 from "../assets/img/Knife_Hit.png";
import projCocosImg4 from "../assets/img/Tofu_Girl.png";
import projCocosImg5 from "../assets/img/logoY.png";
import projEgretImg1 from "../assets/img/Richman.png";
import projEgretImg2 from "../assets/img/Princess_Project.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projectsUnity = [
    {
      title: "Little Ghost",
      description: (
      <div>
        &larr;&larr; Play &rarr;&rarr;
        <br />
        <div className="rgbEffect" style={{ fontSize: "24px" }}>Professional Team</div>
        Worked on: UI, Controls, Main Character.
      </div>
      ),
      imgUrl: projUnityImg1,
      url: "https://store.steampowered.com/app/1587380/Little_Ghost/",
    },
  ];

  const projectsCocos = [
    {
      title: "Duck Hunt",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          Worked on: The Whole Game.
        </div>
      ),
      imgUrl: projCocosImg1,
      url: "https://ang3ldesu.itch.io/duck-hunt"
    },
    {
      title: "Sheep Jump",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          Worked on: The Whole Game.
        </div>
      ),
      imgUrl: projCocosImg2,
      url: "https://ang3ldesu.itch.io/sheep-jump"
    },
    {
      title: "Knife Hit",

      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          Worked on: The Whole Game.
        </div>
      ),
      imgUrl: projCocosImg3,
      url: "https://ang3ldesu.itch.io/knife-hit"
    },
    {
      title: "Tofu Princess",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          Worked on: The Whole Game.
        </div>
      ),
      imgUrl: projCocosImg4,
      url: "https://ang3ldesu.itch.io/tofu-princess"
    },
    {
      title: "Yathzee",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          Worked on it.
        </div>
      ),
      imgUrl: projCocosImg5,
      url: "https://ang3ldesu.itch.io/Yathzee"
    },
  ];

  const projectsEgret = [
    {
      title: "Richman",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          <div className="rgbEffect" style={{ fontSize: "24px" }}>Professional Team</div>
          Worked on: Adding New Content, English and Spanish Version (Including Translations), Fixing Bugs.
        </div>
      ),
      imgUrl: projEgretImg1,
      url: "https://6699.me/sekutori"
    },
    {
      title: "Princess Project",
      description: (
        <div>
          &larr;&larr; Play &rarr;&rarr;
          <br />
          <div className="rgbEffect" style={{ fontSize: "24px" }}>Professional Team</div>
          Worked on: English Version (Including Translations), Fixing Bugs.
        </div>
      ),
      imgUrl: projEgretImg2,
      url: "https://6699.me/palace"
    },
  ];
  const projectsConsole = [
    {
      title: "RomsDream.Inc",
      description: (
        <div>
          &larr;&larr; <div className="rgbEffect" style={{ fontSize: "24px" }}>Developer Account</div> &rarr;&rarr;
          <br />
          Just My Developer Account.
        </div>
      ),
      imgUrl: projConsoleImg1,
      url: "https://play.google.com/store/apps/details?id=com.Romsdream.inc.CasualHockeyShoot"
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility once>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>Let’s Go! <br></br> (If you are using a mobile, double-click and hold the image to view details.)</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Unity</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Cocos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Egret</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Play Console</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projectsUnity.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projectsCocos.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {projectsEgret.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Row>
                          {projectsConsole.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
